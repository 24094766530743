<template>
<div class="reserve-form-wrap">
	<!-- 예약입력  -->
	<div class="reserve-form-wrap--info" v-if="MODE == 1">
		<div class="reserve-form-wrap--top">
			<p class="reserve-form-wrap--title"><strong>휴대전화 번호</strong>를<br>입력해주세요.</p>
			<p class="reserve-form-wrap--text">
				방문예약하신 고객님은<br/>
				<strong>예약하신 휴대전화번호로 방문등록</strong>을 하고<br/>
				안내에 따라 입장하여 주시기 바랍니다.
			</p>
		</div>
		<div class="reserve-form-wrap--sub">
			<div class="reserve-form-wrap--sub-kakao">
				방문등록하고 <strong>카카오톡</strong>으로 확인하세요
			</div>
		</div>

	</div>
	<!-- 예약입력 대기상태 -->
	<div class="reserve-form-wrap--info type-wait" v-if="MODE == 2">
		<div class="reserve-form-wrap--top">
			<span class="reserve-form-wrap--title">현재대기</span>
			<strong class="reserve-form-wrap--wait">{{ WAIT_NUM }} <span class="reserve-form-wrap--wait-unit">팀</span></strong>
			<p class="reserve-form-wrap--text">
				상담을 원하시는 고객님께서는<br>
				<strong>휴대전화 번호로 상담시청</strong>을 해주시기 바랍니다.
			</p>
		</div>
		<div class="reserve-form-wrap--sub">
			<div class="reserve-form-wrap--sub-kakao">
				상담석이 지정되면<br>
				<strong>카카오 알림톡</strong>으로 알려드리며,<br>
				현재 대기상황 및 예상 대기시간을 확인 할 수 있습니다.
			</div>
		</div>

	</div>
	<!-- 공통 전화번호 입력 -->
	<div class="reserve-form-wrap--keypad">
		<div class="reserve-form-wrap--keypad-in">
			<div class="reserve-form-wrap--keypad-top">
				<span class="reserve-form-wrap--keypad-prefix">010-</span>
				<input type="text" :value="KEY_IN">
			</div>
		</div>
		<div class="reserve-form-wrap--keypad-chk-wrap">
			<v-checkbox
				class="reserve-form-wrap--keypad-chk"
				:on-icon="'svg-chk-on'"
				:off-icon="'svg-chk-off'"
				label="서비스 이용약관과 개인정보 처리방식에 동의합니다."></v-checkbox>
		</div>
		<div class="reserve-form-wrap--keypad-pad">
			<div><button type="button" @click="FnKeyPad(1)" >1</button></div>
			<div><button type="button" @click="FnKeyPad(2)" >2</button></div>
			<div><button type="button" @click="FnKeyPad(3)" >3</button></div>
			<div><button type="button" @click="FnKeyPad(4)" >4</button></div>
			<div><button type="button" @click="FnKeyPad(5)" >5</button></div>
			<div><button type="button" @click="FnKeyPad(6)" >6</button></div>
			<div><button type="button" @click="FnKeyPad(7)" >7</button></div>
			<div><button type="button" @click="FnKeyPad(8)" >8</button></div>
			<div><button type="button" @click="FnKeyPad(9)" >9</button></div>
			<div><button type="button" class="btn-keypad-del" @click="FnKeyPadDel"><span class="hide">del</span></button></div>
			<div><button type="button" @click="FnKeyPad(0)" >0</button></div>
			<div><button type="button" class="btn-keypad-in">입력</button></div>
		</div>
	</div>
</div>
</template>

<script>
	export default {
		name: 'CAP0100',
		components: {},
		props: {
		},
		data() {
			return {
				MODE: '1', //예약입력 모드 {1: 기본, 2:대기상태}
				WAIT_NUM: '2', //대기팀
				KEY_IN: '', //키입력
			}
		},
		mounted() {},
		computed: {},
		methods: {
			FnKeyPad(e){
				this.KEY_IN += e;
			},
			FnKeyPadDel(){
				this.KEY_IN = this.KEY_IN.slice(0, -1)
			},
		}
	}
</script>

<style>
</style>